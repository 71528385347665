import React, {useState} from 'react'
import {urlFor} from '../helpers/sanity'

export default function CompanyLogos ({logoUrls = []}) {

  const [urls, setUrls] = useState(logoUrls.map(urlFor))

  const nextItem = () => {

    setUrls(prevState => {

      return [].concat(prevState.slice(1)).concat(prevState.slice(0, 1))

    })
  }

  return (
    <div className="company-logos">
      <div className="company-logos__items" onAnimationIteration={nextItem}>
        {urls.map((url, i) => (
          <div key={i} className="company-logos__item">
            <img src={url} />
          </div>
        ))}
      </div>
    </div>
  )
}
