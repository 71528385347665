import React from 'react'
import {useCarrousel} from '../helpers/hooks'
import classnames from 'classnames'

export default function Carrousel ({children, prevIsLastIndex, interval, showDirectionControls, controlImageUrls, showControls = true, controlsInline = true}) {

  const [{isReady, isSingleItem, index, classModifiers}, {goToIndex, nextItem, prevItem}] = useCarrousel(children.length, {interval, prevIsLastIndex})

  const getControls = () => {

    if (controlImageUrls) {

      return children.map((child, i) => (
        <i key={i} className={`carrousel__control carrousel__control${classModifiers[i]}`} onClick={() => goToIndex(i, true)}>
          <img alt={`${i + 1}`} src={controlImageUrls[i]} />
        </i>
      ))

    } else {

      return children.map((child, i) => (
        <i key={i} className={`carrousel__control carrousel__control${classModifiers[i]}`} onClick={() => goToIndex(i, true)}></i>
      ))
    }
  }

  const className = classnames({
    'carrousel': true,
    'carrousel--ready': isReady,
    'carrousel--show-controls': showControls,
    'carrousel--show-direction-controls': showDirectionControls,
    'carrousel--controls-inline': controlsInline,
    'carrousel--single-item': isSingleItem
  })

  return (

    <div className={className} data-index={index}>
      <div className="carrousel__items">
        {children.map((child, i) => (
          <div key={i} className={`carrousel__item carrousel__item${classModifiers[i]}`}>
            {child}
          </div>
        ))}
      </div>
      <div className="carrousel__direction-controls">
        <div className="carrousel__left-button" onClick={() => prevItem(true)}></div>
        <div className="carrousel__right-button" onClick={() => nextItem(true)}></div>
      </div>
      <div className="carrousel__controls">
        {getControls()}
      </div>
    </div>
  )
}
