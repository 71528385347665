import React from 'react'
import {graphql} from 'gatsby'
import {localiseObject} from '../helpers/locale'
import Layout from '../templates/layout'
import Strapline from '../components/strapline'
import ShowcaseRow from '../components/showcase-row'
import CompanyLogos from '../components/company-logos'
import QuoteCarrousel from '../components/quote-carrousel'
import ShowWhenScrolled from '../components/show-when-scrolled'
import Hero from '../components/hero'
import Video from '../components/video'
import Content from '../components/content'
import PageHelmet from '../components/page-helmet'
import {useIsScrolled} from '../helpers/hooks'
import {unblockPortableText, urlFor, getThumbnailUrlFromVideo} from '../helpers/sanity'
import {PAGE_URLS, SITE_URL} from '../helpers/constants'
import '../scss/pages/index.scss'

export const query = graphql`
  query Homepage {
    sanityHomePage {
      title {
        en
        fr
      }
      pageSeo {
        title {
          en
          fr
        }
        description {
          en
          fr
        }
      }
      _rawHero
      hero {
        _key
        _type
        video {
          _key
          _type
          asset {
            _key
            _type
            status
            assetId
            playbackId
            filename
            thumbTime
          }
        }
      }
      clientLogos {
        asset {
          id
        }
      }
      _rawIntro
      services {
        _key
        _type
        title {
          en
          fr
        }
        links {
          _key
          _type
          reference {
            ... on SanityRetailPage {
              id
              title {
                en
                fr
              }
              slug {
                current
              }
              _rawHero
            }
            ... on SanityExperientialPage {
              id
              title {
                en
                fr
              }
              slug {
                current
              }
              _rawHero
            }
            ... on SanityNetworkPage {
              id
              title {
                en
                fr
              }
              slug {
                current
              }
              _rawHero
            }
          }
          image {
            asset {
              _id
            }
          }
        }
      }
      _rawCasestudyList(resolveReferences: {maxDepth: 4})
      _rawPortraitVideos(resolveReferences: {maxDepth: 3})
      _rawQuoteCarousel
    }
  }
`

function Index ({data}) {

  const isScrolled = useIsScrolled()

  const {sanityHomePage: {_rawCasestudyList, services, _rawQuoteCarousel, pageSeo, hero, _rawHero, _rawIntro, clientLogos, _rawPortraitVideos}} = data

  const getCasestudiesShowcase = () => {

    if (!_rawCasestudyList) return null

    const {caption, caseStudies} = _rawCasestudyList

    const showcase = {
      heading: localiseObject(caption),
      horizontal: false,
      showcases: caseStudies.map(({reference}) => {

        const {client, slug: {current}, referenceImage: {asset}, intro} = reference

        return {
          image: urlFor(asset),
          title: client,
          description: unblockPortableText(localiseObject(intro)),
          url: `/${PAGE_URLS.CASESTUDIES}/${current}`
        }
      })
    }

    return (
      <ShowWhenScrolled>
        <ShowcaseRow {...showcase} />
      </ShowWhenScrolled>
    )
  }

  const getServicesShowcase = () => {

    if (!services) return null

    const {title, links} = services

    const showcase = {

      heading: localiseObject(title),
      horizontal: true,
      showcases: links.map(service => {

        const {reference: {title, _rawHero, slug: {current}}, image: {asset}} = service

        return {
          image: urlFor(asset),
          title: localiseObject(title),
          description: unblockPortableText(localiseObject(_rawHero.body)),
          url: `/${current}`
        }
      })
    }

    return (
      <ShowWhenScrolled>
        <ShowcaseRow {...showcase} />
      </ShowWhenScrolled>
    )
  }

  const getQuoteCarrousel = () => {

    if (!_rawQuoteCarousel) return null

    const quoteCarrousel = {

      heading: 'What they have said',
      quotes: _rawQuoteCarousel.map(companyQuote => {

        const {quote, name, company, logo: {asset}} = companyQuote

        return {
          text: unblockPortableText(quote.en),
          subtext: `${name} - ${company || ''}`,
          logoUrl: urlFor(asset)
        }
      })
    }

    return (
      <ShowWhenScrolled>
        <QuoteCarrousel {...quoteCarrousel} />
      </ShowWhenScrolled>
    )
  }

  return (
    <Layout>
      <PageHelmet
        url={SITE_URL}
        pageSeo={pageSeo}
        description={{en: 'Social Vend Website'}}
        imageUrl={getThumbnailUrlFromVideo(hero.video.asset)}
      />
      <Hero
        fill={isScrolled}
      >
        <Video assetDocument={hero.video.asset} />
        <Strapline
          text={unblockPortableText(localiseObject(_rawHero.body))}
          heading={localiseObject(_rawHero.title)}
        />
      </Hero>
      <CompanyLogos
        logoUrls={
          clientLogos.map(({asset}) => asset.id)
        }
      />
      <ShowWhenScrolled>
        <Strapline
          text={unblockPortableText(localiseObject(_rawIntro.body))}
          heading={localiseObject(_rawIntro.caption)}
        />
      </ShowWhenScrolled>
      {getServicesShowcase()}
      <Content content={_rawPortraitVideos} />
      {getCasestudiesShowcase()}
      {getQuoteCarrousel()}
    </Layout>
  )
}

export default Index
