import React from 'react'
import Carrousel from './carrousel'

export default function QuoteCarrousel ({quotes, heading}) {

  return (
    <section className="quote-carrousel">
      {heading && <h1 className="heading">{heading}</h1>}
      <Carrousel controlImageUrls={quotes.map(quote => quote.logoUrl)} controlsInline={false} prevIsLastIndex={true}>
        {quotes.map(({text, subtext}, i) => (
          <div key={i} className="quote-carrousel__item">
            {text}
            <cite>{subtext}</cite>
          </div>
        ))}
      </Carrousel>
    </section>
  )
}
